import Swal from 'sweetalert2'
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: 'https://70ad4a0acd3346bc8d40df5578d4d662@o551403.ingest.sentry.io/5674840',
    normalizeDepth: 5
});


window.addEventListener('unhandledrejection', function (e) {
    console.log("unhandledrejection", e);
    Swal.fire("Fejl", "Der er gået noget galt. Du kan manuelt indsende en fuldmagt <a href='https://www.ansvarligfremtid.dk/generalforsamlinger/'>på denne side</a>", "error")
})

window.onerror = function (message, file, line, col, error) {
    console.log("window.onerror", message);
    Swal.fire("Fejl", "Der er gået noget galt. Du kan manuelt indsende en fuldmagt <a href='https://www.ansvarligfremtid.dk/generalforsamlinger/'>på denne side</a>", "error")
        .then(res => {
            console.log(res);
        });
    return false;
};
window.addEventListener("error", function (e) {
    console.log("erroreventlistener", e);
    Swal.fire("Fejl", "Der er gået noget galt. Du kan manuelt indsende en fuldmagt <a href='https://www.ansvarligfremtid.dk/generalforsamlinger/'>på denne side</a>", "error")
        .then(res => {
            console.log(res);
        })
    return false;
})
